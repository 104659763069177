
.card {
    cursor: pointer
}

.hd {
    font-size: 25px;
    font-weight: 550
}

.card.hover,
.card:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, .2)
}

.card-title {
    font-weight: 600
}

.ft {
    margin-top: 25px
}

.chk {
    margin-bottom: 5px
}

.rck {
    margin-top: 20px;
    padding-bottom: 15px
}

.card-subtitle {    
    min-height: 150px;
}

.services a{
    color: #6c757d;
}
.imageCenterAlign{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;    
}
.imageCenterAlign img{
    max-width: 75%;
}
.servv ul{
    margin-bottom: 3px;
}