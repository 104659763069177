.portfolio .content {
  position: relative;
  margin: auto;
  overflow: hidden;
  margin-bottom: 25px;
  border: 1px solid #ccc;
  /* border-radius: 10%; */
  box-shadow: 0 0 20px rgb(8 48 89 / 40%);
}

.portfolio .content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.portfolio .content:hover .content-overlay {
  opacity: 1;
}

.portfolio .content-image {
  width: 100%;
}

.portfolio .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  padding: 25px;
}

.portfolio .content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.portfolio .content-details h3 {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.portfolio .content-details p {
  color: #fff;
  font-size: 0.8em;
}
.portfolio .content-details i { 
  font-size: 20px;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  border: 1px solid #fff;   
  vertical-align: middle;
}



.portfolio .fadeIn-bottom {
  top: 80%;
}

.portfolio .fadeIn-top {
  top: 20%;
}

.portfolio .fadeIn-left {
  left: 20%;
}

.portfolio .fadeIn-right {
  left: 80%;
}