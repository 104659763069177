
/* .bgeffect-01 */
.bgeffect-01{
    text-align: left;
    background: #fff;
    height: 440px;
    padding: 25px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border: 1px solid #e1d4cf;
}
.bgeffect-01:hover p,
.bgeffect-01:hover .title
{    
    color: #fff;
    opacity: .9;
}
.bgeffect-01:before {
    position: absolute;
    background: linear-gradient(45deg, #e83963, #fe8704) !important;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto;
    left: 33%;
    content: "";
    top: 33px;
    opacity: .15;
    -moz-transition: all .2s ease-out 0s;
    -webkit-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    animation: leftright 3s infinite alternate;
}
.bgeffect-01:hover:before {
    position: absolute;
    background: linear-gradient(to bottom, #4149c5 0, #fe214f 100%) !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    border-radius: 0;
    opacity: 1;
    animation: none;
}

/* .bgeffect-02 */
.bgeffect-02{
   position: relative;
   margin-bottom: 40px;   
}
.bgeffect-02 .inner-box {
    position: relative;
    padding: 50px 25px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fe214f;
}
.bgeffect-02 .inner-box .icon-box {
    position: relative;
    line-height: 1em;
    font-size: 62px;
    color: #fff;
}
.bgeffect-02 .inner-box h2 {
    position: relative;
    color: #fff;
    line-height: 1.3em;
    font-weight: 700;
    margin: 14px 0;
    font-size: 18px;
}
.bgeffect-02 .inner-box .text {
    position: relative;
    color: #fff;
    font-size: 16px;
    line-height: 1.7em;
}

.bgeffect-02 .inner-box .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    opacity: 0;
    -webkit-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -moz-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    -o-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -ms-transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.bgeffect-02 .inner-box:hover .overlay-box {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0);
    -moz-transform: perspective(400px) rotateX(0);
    -ms-transform: perspective(400px) rotateX(0);
    -o-transform: perspective(400px) rotateX(0);
    transform: perspective(400px) rotateX(0);
}
.bgeffect-02 .inner-box .overlay-box:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .9);
}
.bgeffect-02 .inner-box .overlay-box .overlay-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;
    padding: 10px 30px;
}
.bgeffect-02 .inner-box .overlay-box .overlay-inner .content {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}
.bgeffect-02 .inner-box .overlay-box h3 {
    position: relative;
    line-height: 1.3em;
    font-weight: 700;
    margin: 15px 0 12px;
    font-size: 14px;
}

.bgeffect-02  .inner-box .overlay-box .icon-two {
    position: relative;
    line-height: 1em;
    font-size: 62px;
    color: #fff;
}


/* .bgeffect-03 */
.bgeffect-03 {
    position: relative;
}
.bgeffect-03 .inner-box {
    position: relative;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    margin-bottom: 40px;
}
.bgeffect-03  .inner-box .inner-content {
    position: relative;
    overflow: hidden;
    padding: 70px 20px;
    z-index: 2;
    min-height: 592px;
}
.bgeffect-03 .inner-box .icon-box {
    position: relative;
}
.bgeffect-03 .inner-box .icon-box .icon {
    position: relative;
    color: #fe214f;
    font-size: 90px;    
}
.bgeffect-03 .inner-box h3 {
    font-size: 20px;
    color: #333;
}
.bgeffect-03 .inner-box .text {
    position: relative;
    color: #687693;
    font-size: 16px;
    line-height: 1.7em;
}
.bgeffect-03 .inner-box .side-icon, .bgeffect-03 .overlay-inner .left-top-icon {
    position: initial !important;
}
.bgeffect-03 .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    border-radius: 8px;
    text-align: center;
    -webkit-transform: perspective(400px) rotateY(-90deg);
    -moz-transform: perspective(400px) rotateY(-90deg);
    -ms-transform: perspective(400px) rotateY(-90deg);
    -o-transform: perspective(400px) rotateY(-90deg);
    transform: perspective(400px) rotateY(-90deg);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -ms-transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-image: -ms-linear-gradient(top, #ce2c00 0, #fd4b1b 100%);
    background-image: -moz-linear-gradient(top, #ce2c00 0, #fd4b1b 100%);
    background-image: -o-linear-gradient(top, #ce2c00 0, #fd4b1b 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ce2c00), color-stop(100, #fd4b1b));
    background-image: -webkit-linear-gradient(top, #ce2c00 0, #fd4b1b 100%);
    background-image: linear-gradient(to bottom, #e20031 0, #e20031 100%);
}
.bgeffect-03 .inner-box:hover .overlay-box {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    visibility: visible;
    color: #fff;
}

.bgeffect-03 .inner-box:hover h3 {    
    color: #fff;
}

.bgeffect-03 .inner-box:hover .icon-box .icon {
    color: #fff;   
}
.bgeffect-03 .inner-box:hover h3 {
    color: #fff;
}
.bgeffect-03 .inner-box:hover .text {
    color: #fff;
}