/** grphd01 **/
.grphd01 {
    background-position: 0 0;
    background-repeat: no-repeat;
    padding: 50px 0;
}

.grphd01 .title {
    position: relative;
    color: #106eea;
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-right: 60px;
    display: inline-block;
    font-family: Montserrat, sans-serif;
}

.grphd01 .title:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 12px;
    height: 2px;
    width: 50px;
    background-color: #106eea;
}

.grphd01 .sec1_heading {
    font-size: 36px;
}

.grphd01 .mar-vh {
    margin-top: 35px;
    margin-bottom: 25px;
}

.grphd01 .about-text {
    line-height: 28px;
    font-size: 20px;
    padding-top: 20px;
    visibility: visible;
    animation-name: fadeInUp;
    letter-spacing: .2px;
}

.grphd01 .cls-bghf {
    background: white;
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
    padding: 15px 22px 19px 26px;
    margin-top: 52px;
    border-left: 6px solid #106eea;
}

.grphd01 .formbg {
    background: #f6f9fe;
    border: 5px solid #106eea;
    background-size: cover;
    border-radius: 10px;
}

.grphd01 .formbg form {
    padding-bottom: 60px;
}

.grphd01 .formbg form {
    padding: 22px 27px;
}

.grphd01 .form-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #000;
}

.grphd01 .sep_line {
    height: 21px;
    width: 17% !important;
    border-top-width: 10px;
    border-top: 4px solid #111010;
    display: block;
    border-color: #322e2e;
    position: relative;
    top: 1px;
}

.grphd01 input,
.grphd01 textarea {
    /* margin-bottom: 15px !important; */
}
/** grphd02 **/
.grphd02{
    position: relative;  
    background-color: #f2f2f2;     
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;

}
.grphd02 .secline {
    line-height: 1.5;
}
.grphd02 .mrgg {
    margin-bottom: 26px;
    margin-top: 34px;
}
.grphd02 .about-text {
    line-height: 32px;    
    padding-top: 20px;
    visibility: visible;
    animation-name: fadeInUp;
    letter-spacing: .2px;
}
.grphd02 .ch-bord {
    border-left: 4px solid #106eea;
    padding-left: 26px;
    padding-bottom: 20px;
    padding-top: 20px;
}

/** grphd03 **/
.grphd03{
    position: relative;       
    padding: 40px 5%;
    background-color: #0a4590;
}
.grphd03 .explore-btn {
    text-align: center;
}

.grphd03 a{
    color: #fff;
}

.grphd03 .btn {
    color: var(--color);
    background: #fff;
}

/** grphd04 **/
.grphd04{
    position: relative;
    padding: 70px 0 60px;
}

.grphd04 .justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center!important;
}
.grphd04 .dream-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.grphd04 .pb-4 {
    padding-bottom: 4%;
}

.grphd04 .about-text {
    line-height: 32px;
    font-size: 18px;
    padding-top: 20px;
    visibility: visible;
    animation-name: fadeInUp;
    letter-spacing: .2px;
}
.grphd04 .service-block {
    position: relative;
}
.grphd04 .service-block .inner-box {
    position: relative;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);    
}
.grphd04 .service-block .inner-box:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;    
    z-index: -1;
}
.grphd04 .service-block .inner-box .inner-content {
    position: relative;    
    padding: 35px 20px;
    z-index: 2;    
}
.grphd04 .service-block .inner-box .icon-box {
    position: relative;
    padding-bottom: 15px;
}
.grphd04 .service-block .inner-box .icon-box .icon {
    position: relative;
    color: #106eea;
    font-size: 60px;
    line-height: 1em;
}
.grphd04 .service-block .inner-box h3 {
    font-size: 20px;
}
.grphd04 .service-block .inner-box .text {
    position: relative;
    color: #687693;
    font-size: 18px;
    line-height: 1.7em;
}
.grphd04 .service-block .inner-box .side-icon {
    position: absolute;
    right: -20px;
    bottom: -20px;
    color: #f2f4fa;
    font-size: 98px;
    line-height: 1em;
}
.grphd04 .service-block .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    border-radius: 8px;
    text-align: center;   
    transition: all .2s;
    background: linear-gradient(to bottom, #19A7CE 0, #0a4590 100%) !important;
}
.grphd04 img{
    width: 75px;
}
.grphd04 .service-block .overlay-box:before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 175px;
    height: 185px;
    background-repeat: no-repeat;
    
}
.grphd04 .service-block .overlay-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px 0;
    text-align: center;
}    
.grphd04 .service-block .overlay-inner .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 55px;
}

.grphd04 .service-block .inner-box:hover .overlay-box {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    visibility: visible;
    color: #fff;
}
.grphd04 .service-block .inner-box:hover .icon,
.grphd04 .service-block .inner-box:hover h3 {    
    color: #fff;
}
.grphd04 .service-block .inner-box:hover .text {    
    color: #eee;
}


/** grphd05 **/
.grphd05 {   
    background-color: #f2f2f2; 
    background-position: top left;
    background-repeat: no-repeat;
    padding: 50px 0;
}

.grphd05 .ct-heading .item--title {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 30px;
    color: #333;
}

.grphd05 .ct-heading .item--title.st-line-left1 span {
    padding-left: 80px;
    position: relative;
    display: inline-block;
}

.grphd05 .ct-heading .item--title.st-line-left1 span i {
    width: 54px;
    height: 3px;
    position: absolute;
    top: 21px;
    left: 0;
    background-color: #106eea;
}

.grphd05 .text-center {
    text-align: center;
}
.grphd05 .card {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    padding: 25px;
    border-radius: 10px;    
}
.grphd05 .choose-image-box {   
    background-color: #106eea;
    border-radius: 100%;
    display: inline-block;
    width: 75px;
    height: 75px;
    transition: 0.6s;
    padding: 13px;
    margin: 0 0px 10px 0;
}
.grphd05 .choose-image-box img {
    width: 50px;
}
.grphd05 h4.card-title {
    font-weight: 600;
}
.grphd05 hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

/** grphd06 **/
.grphd06 {   
    padding: 60px 0;
    padding-bottom: 1%;
}
.grphd06 .dream-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}
.grphd06 .h3, h3 {
    font-size: 24px;
}
.grphd06 .text-justify {
    text-align: justify;
}
.grphd06 .map {   
    background-size: cover;
}
.grphd06 .count {
    font-size: 150px;
    color: #000;
    font-weight: 700;
}
.grphd06 .cu {
    /* font-size: 28px!important;
    font-weight: 600; */
}
.grphd06 .pl {
    color: #106eea;
    font-size: 90px;
    font-weight: 700;
}
.grphd06 sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
.grphd06 .col-md-4 {
    padding: 0;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
.grphd06 .client-image {
    padding: 41px 20px;
    text-align: center;
}