#terms .nav{
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display:inline;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

#terms .nav-link {
    display: block;
    padding: 10px;
    font-size: 18px;
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--color);
    text-decoration: none;      
}