.ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
}

#hero .carousel-indicators{
  bottom: -50px;
}

#hero .servimg{
  text-align: right;
}