section.porject-section-main {
    padding: 0px 0 40px;
}
section.porject-section-main .post-row { 
    border: 1px solid #ccc;
    border-bottom: 5px solid #0a4590;
    /* border-radius: 25px; */
    margin-bottom: 65px;
}
.picture-boxes {
    background: #eee;
    max-width: 100%;
    display: table;    
    /* height: 370px;
    border-radius: 25px; */
    padding: 0;
}
.picture-boxes .thumb.entry-thumbnail {    
    margin: 0 auto;
    /* display: table-cell; */
    vertical-align: middle;
    /* padding: 10px 0px; */
}
.project-info { 
    position: relative;
    padding: 35px 20px;
    width: 100%;
    transition: top .5s ease-in-out;
}

.cat-title-bs {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    margin-top: 5px;
}
.project-info .overlay-innerd h4 {
    margin: 0 0 10px;
    letter-spacing: 0;
    color: #191919;
    opacity: 1;   
    font-size: 22px;    
    clear: both;
}
.project-info .overlay-innerd p { 
    line-height: 21px;
    letter-spacing: 0;
    color: #666;
    opacity: 1;
}
.project-info .overlay-innerd h5 {    
    letter-spacing: .58px;
    color: #191919;
    opacity: 1;
    margin-bottom: 3px;
}
ul.tech-list {
    margin: 5px 0 15px;
}
ul {
    padding: 0;
}

ul.tech-list li {
    display: inline-block;
    padding: 3px 10px;
    margin: 0 10px 4px 0;
    /* border-radius: 3px; */
    background: #666;    
    letter-spacing: .51px;
    color: #fff;
    opacity: 1;
    font-size: 14px;
}
li {
    list-style: none;
    line-height: 25px;
}
.project-info .overlay-innerd .link-block {
    padding-top: 15px;    
}
.project-info .overlay-innerd .link-block a.r-button.cast-sudy-btn {
    padding: 8px 15px!important;
}
.project-info .overlay-innerd .link-block a.r-button {
    margin: 0 8px 0 0;
    padding: 8px 15px 8px 15px;
    background: #0a4590;
    border-radius: 5px;
    font-size: 16px;   
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    border: 0;
    position: relative;
}
.row-reverse {
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.custom-select{
    padding: 10px 10px;
    border: 1px solid #0a4590;    
}

.project-info i {
    font-size: 25px;   
    vertical-align: middle;
    border: 1px solid #2443a7;
    padding: 2px 10px;
    border-radius: 5px;
}
