.our-team-main {
  position: relative;
  box-shadow: 0 0 15px 0 rgba(5, 62, 219, 0.25);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.our-team-main .img-circle {
  border-radius: 50%;
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
  background: #0b32c1;
  text-align: center;
  margin: 0 auto;
  border: 10px solid #0d6efd;
}

.our-team-main img {
  border-radius: 50%;
  background: #eef;
}

.our-team-main h3 {
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.our-team-main p {
  margin-bottom: 0;
}

.team-back {
  width: 100%;
  height: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  text-align: left;
  /* background: #fff; */
  padding: 15px 30px;
  color: #fff;
}

.team-back h3 {
  color: #fff;
}

.team-back ul li {
  font-size: 18px;
}

.team-front {
  width: 100%;
  height: 375px;
  position: relative;
  z-index: 10;
  background: #fff;
  padding: 35px 35px;
  bottom: 0px;
  transition: all 0.5s ease;
}

.our-team-main:hover .team-front {
  bottom: -425px;
  transition: all 0.5s ease;
}

.our-team-main:hover {
  border-color: #777;
  color: #333;
  transition: 0.5s;
}

.cutout-text {
  background-color: white;
  color: black;
  font-size: 10vw;
  font-weight: bold;
  margin: 0 auto;
  padding: 10px;
  width: 70%;
  text-align: center;
}