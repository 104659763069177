.webdevelopment {
    padding: 50px 0
}

.inner-column1 {
    position: relative;
    /* padding: 19px 0 19px 31px;
    border-left: 2px dashed #bbb */
}

.mt-20 {
    margin-top: 3%
}

.sec-head {
    font-size: 33px;
    font-weight: 700;
    line-height: 43px
}

.featured-section-two {
    position: relative;
    padding: 50px 0 50px;
    background-color: #f5f5f5
}

.featured-section-two .patern-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 462px;
    height: 488px;
    background-repeat: no-repeat
}

.featured-section-two .patern-layer-two {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 462px;
    height: 512px;
    background-repeat: no-repeat
}

.featured-section-two .blocks-column .feature-block:nth-child(2) {
    /* margin-top: 70px */
}

.featured-section-two .blocks-column .feature-block:nth-child(3) {
    /* margin-top: -45px */
}

.feature-block {
    position: relative;
    margin-bottom: 25px
}

.feature-block .inner-box {
    position: relative;
    padding: 50px 25px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    background-color: #106eea
}

.feature-block .inner-box .icon-box {
    position: relative;
    line-height: 1em;
    font-size: 62px;
    color: #fff
}

.feature-block .inner-box h2 {
    position: relative;
    color: #fff;
    line-height: 1.3em;
    font-weight: 700;
    margin: 14px 0;
    font-size: 18px;
}

.feature-block .inner-box .text {
    position: relative;
    color: #fff;
    font-size: 16px;
    line-height: 1.7em
}

.feature-block .inner-box .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    opacity: 0;
    -webkit-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -moz-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    -o-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -ms-transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.feature-block .inner-box:hover .overlay-box {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0);
    -moz-transform: perspective(400px) rotateX(0);
    -ms-transform: perspective(400px) rotateX(0);
    -o-transform: perspective(400px) rotateX(0);
    transform: perspective(400px) rotateX(0)
}

.feature-block .inner-box .overlay-box:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color:#ccc
}

.feature-block .inner-box .overlay-box .overlay-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;
    padding: 10px 30px
}

.feature-block .inner-box .overlay-box .overlay-inner .content {
    position: relative;
    display: table-cell;
    vertical-align: middle
}

.feature-block .inner-box .overlay-box .icon-two {
    position: relative;
    line-height: 1em;
    font-size: 62px;
    color: #fff
}

.feature-block .inner-box .overlay-box h3 {
    position: relative;
    line-height: 1.3em;
    font-weight: 700;
    margin: 15px 0 12px;
    font-size: 18px;
}

.feature-block .inner-box .overlay-box h3 a {
    position: relative;
    color: #106eea
}

.feature-block .inner-box .overlay-box .read-more {
    position: relative;
    color: #fff;
    font-size: 15px;
    margin-top: 60px;
    display: inline-block;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.feature-block .inner-box .overlay-box .read-more:before {
    position: absolute;
    content: '';
    left: 50%;
    top: -60px;
    width: 4px;
    height: 50px;
    margin-left: -2px;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    background-color: rgba(250, 250, 250, .2)
}

.feature-block .inner-box .overlay-box .read-more:hover::before {
    background-color: rgba(178, 121, 39, .8)
}

.feature-block .inner-box .overlay-box .read-more:hover {
    color: #b27927
}

.featured-section-two .content-column {
    position: relative
}

.featured-section-two .content-column .inner-column {
    position: relative;
    padding-left: 40px
}

.featured-section-two .content-column .blocks-outer .feature-block-two:last-child {
    margin-bottom: 0
}

.featured-section-two .content-column .link-box {
    position: relative;
    margin-top: 50px
}

.featured-section-two .content-column .link-box .video-box {
    position: relative;
    width: 60px;
    height: 60px;
    color: #381d01;
    font-size: 18px;
    text-align: center;
    border-radius: 50px;
    line-height: 60px;
    display: inline-block;
    float: left;
    margin-right: 40px;
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    background-color: #fff
}

.featured-section-two .content-column .link-box .video-box .overlay-box span {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    z-index: 99;
    color: #381d01;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    padding-left: 7px;
    background-color: #fff;
    display: inline-block;
    margin-top: -30px;
    margin-left: -30px;
    transition: all .9s ease;
    -moz-transition: all .9s ease;
    -webkit-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1)
}

.featured-section-two .content-column .link-box .video-box .ripple,
.featured-section-two .content-column .link-box .video-box .ripple:after,
.featured-section-two .content-column .link-box .video-box .ripple:before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 60px;
    width: 60px;
    margin-left: .5px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(177, 120, 39, .4);
    -moz-box-shadow: 0 0 0 0 rgba(177, 120, 39, .4);
    -ms-box-shadow: 0 0 0 0 rgba(177, 120, 39, .4);
    -o-box-shadow: 0 0 0 0 rgba(177, 120, 39, .4);
    box-shadow: 0 0 0 0 rgba(177, 120, 39, .4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite
}

.featured-section-two .content-column .link-box .video-box .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute
}

.featured-section-two .content-column .link-box .video-box .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute
}

.feature-block-two {
    position: relative;
    margin-bottom: 45px
}

.feature-block-two .inner-box {
    position: relative;
    padding-left: 100px
}

.feature-block-two .inner-box .icon-box {
    position: absolute;
    left: 0;
    top: 5px;
    line-height: 1em;
    color: #361b00;
    font-size: 60px
}

.feature-block-two .inner-box h3 {
    position: relative;
    line-height: 1.4em;
    font-weight: 700;
    font-size: 18px;
}

.feature-block-two .inner-box h3 a {
    position: relative;
    color: #333;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.feature-block-two .inner-box h3 a:hover {
    color: #371c00
}

.feature-block-two .inner-box .text {
    position: relative;
    color: #666;
    font-size: 16px;
    line-height: 1.6em;
    margin-top: 10px
}

.btn-style-nine {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    font-weight: 600;
    overflow: hidden;
    background: 0 0;
    border-radius: 6px;
    padding: 15px 44px 15px;
    text-transform: capitalize;
    background-image: -ms-linear-gradient(left, #b17827 0, #5e3c1a 100%);
    background-image: -moz-linear-gradient(left, #b17827 0, #5e3c1a 100%);
    background-image: -o-linear-gradient(left, #b17827 0, #5e3c1a 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #b17827), color-stop(100, #5e3c1a));
    background-image: -webkit-linear-gradient(left, #b17827 0, #5e3c1a 100%);
    background-image: linear-gradient(to right, #f62420 0, #337ab7 100%)
}

.features-item {
    text-align: left;
    background: #eee;    
    padding: 40px 20px;
    position: relative;    
    border: 1px solid #e1d4cf
}

.features-item:before {
    position: absolute;
    background: linear-gradient(45deg, #4149c5, #106eea) !important;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    left: 120px;
    content: "";
    top: 33px;
    opacity: .15;
    -moz-transition: all .2s ease-out 0s;
    -webkit-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    animation: leftright 3s infinite alternate
}

.features-item i:before {
    margin: 0;
    color: #106eea;
    font-size: 50px;
    line-height: 50px;
    display: block;
    vertical-align: middle;
    -moz-transition: all .2s ease-out 0s;
    -webkit-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}

.features-item .feature-title {
    margin-top: 30px;    
    margin-bottom: 15px;
    font-size: 22px;
}

.features-item:hover:before {
    background: linear-gradient(to bottom, #4149c5 0, #106eea 100%) !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    border-radius: 0;
    opacity: 1;
    animation: none
}

.features-item:hover .feature-title,
.features-item:hover i:before,
.features-item:hover p {
    color: #fff;
    opacity: .9
}

.leftright {
    animation: leftright 1s infinite alternate
}

.text-white {
    color: #fff
}

.ct-counter-holder {
    padding: 10%;
    background-color: rgb(11 60 153 / 72%);
    text-align: center;
    border: 1px solid #aaa;
    box-shadow: 0 18px 18px rgba(0, 8, 67, .15);
    border-radius: 4px
}

@-webkit-keyframes swing {
    15% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    30% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }

    65% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }

    80% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes swing {
    15% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    30% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }

    65% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }

    80% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.swing:hover {
    -webkit-animation: swing 1s ease;
    animation: swing 1s ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1
}

.future {    
    background-position: top left;
    background-repeat: no-repeat;
    padding: 50px 0;
}

.ct-heading .item--title {
    font-size: 36px;
    /* line-height: 44px; */
    font-weight: 700;
    margin-bottom: 31px
}

.ct-heading .item--title.st-line-left1 span {
    padding-left: 82px;
    position: relative;
    display: inline-block
}

.ct-heading .item--title.st-line-left1 span i {
    width: 54px;
    height: 3px;
    position: absolute;
    top: 21px;
    left: 0;
    background-color: #106eea;
    background-image: -webkit-gradient(linear, left top, right top, from(#106eea), to(#106eea));
    background-image: -webkit-linear-gradient(left, #106eea, #106eea);
    background-image: -moz-linear-gradient(left, #106eea, #106eea);
    background-image: -ms-linear-gradient(left, #106eea, #106eea);
    background-image: -o-linear-gradient(left, #106eea, #106eea);
    background-image: linear-gradient(left, #106eea, #106eea)
}

.ct-heading {
    position: relative
}

.top-bt {
    border-top: 6px solid red
}

.pab {
    height: 100%;
    width: 100%;
    background-color: #fff;
    top: 0;
    left: 0
}

.bg-layer {
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #fff;
    left: 0
}

.mt {
    margin-top: -60px;
    margin-left: -40px;
    padding-top: 50px;
    padding-right: 8%;
    height: 121vh;
    padding-bottom: 50px;
    padding-left: 55px;
    background-color: #fff
}

.process1 {
    background-color: #f5faff;
    padding-bottom: 6%
}

.process-head {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px
}

.num {
    width: 2.5em !important;
    height: 2.5em !important;
    background-color: #e20031;
    display: table-cell;
    border-radius: 50%;
    margin-bottom: 6%;
    color: #fff;
    float: left;
    margin-right: 6%;
    line-height: 35px;
    text-align: center
}

.p1 {
    padding-top: 5%
}

.cd {
    display: flex;
    border-bottom: 1px dotted #c3c2c2;
    padding-top: 3%
}

.why {
    background-size: cover;
    position: relative;
    padding: 50px 0
}

.my-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(14, 14, 14, .85);
    opacity: 1
}

.grid-item-holder {
    padding: 40px 28px 38px;
    position: relative;
    height: 100%;    
}

.grid-item-holder .item--overlay {
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #106eea;
    background-image: -webkit-gradient(linear, left top, right top, from(#106eea), to(#106eea));
    background-image: -webkit-linear-gradient(left, #ff214f, #337ab7);
    background-image: -moz-linear-gradient(left, #ff214f, #106eea);
    background-image: -ms-linear-gradient(left, #106eea, #106eea);
    background-image: -o-linear-gradient(left, #106eea, #106eea);
    background-image: linear-gradient(left, #106eea, #106eea);
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
    -webkit-transition: all .2s linear 0s;
    -khtml-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition: all .2s linear 0s;
    opacity: 0
}

.grid-item-inner {
    background-color: #fff;
    -webkit-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -khtml-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -moz-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -ms-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -o-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    box-shadow: 0 8px 40px rgba(12, 12, 12, .1)
}



.item--icon {
    line-height: 1;
    margin: 0 auto 13px auto
}

.item--icon i {
    font-size: 62px;
    -webkit-transition: all .2s linear 0s;
    -khtml-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition: all .2s linear 0s
}

.item--title {
    font-size: 20px;
    margin-bottom: 14px;
    color: #333
}

.item--content {
    font-size: 16px;
    line-height: 26px;
    color: #333
}

.item--icon-abs i {
    font-size: 70px;
    opacity: .45
}

.text-gradient {
    background-color: #106eea;
    background-image: -webkit-gradient(linear, left top, right top, from(#106eea), to(#106eea));
    background-image: -webkit-linear-gradient(left, #ff214f, #106eea);
    background-image: -moz-linear-gradient(left, #ff214f, #106eea);
    background-image: -ms-linear-gradient(left, #106eea, #106eea);
    background-image: -o-linear-gradient(left, #106eea, #106eea);
    background-image: linear-gradient(left, #106eea, #106eea);
    background-color: transparent;
    background-clip: text;
    -o-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent
}

.grid-item-inner {
    background-color: #fff;
    -webkit-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -khtml-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -moz-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -ms-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    -o-box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    box-shadow: 0 8px 40px rgba(12, 12, 12, .1);
    position: relative;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    z-index: 1
}

.item--icon-abs {
    line-height: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transition: all .2s linear 0s;
    -khtml-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition: all .2s linear 0s
}

.grid-item-inner:hover .grid-item-holder .item--overlay {
    opacity: 1
}

.ct-service-grid3 .grid-item-inner:hover .item--icon i,
.grid-item-inner:hover .item--icon i {
    background-image: none;
    text-fill-color: transparent;
    -o-text-fill-color: #fff;
    -ms-text-fill-color: #fff;
    -moz-text-fill-color: #fff;
    -webkit-text-fill-color: #fff
}

.grid-item-inner:hover .item--content,
.grid-item-inner:hover .item--title {
    color: #fff
}

.para-three-service {
    font-weight: 400;
    /* color: #fff; */
    text-align: center;    
    line-height: 30px !important;
    padding: 10px 30px
}

.inner-three-service {
    height: auto;
    background-size: cover;
    text-align: center;
    padding: 50px 0;
    overflow: hidden
}


.over-bg-1 {
    background: #ef0833bf
}

.over-bg-2 {
    background: #000833bf
}

.over-bg-3 {
    background: #0060c2bf
}

.z-index-99 {
    position: relative;
    z-index: 99
}

.inner-three-service img {
    width: 17%
}

.heading-service {
    /* color: #fff; */
    font-weight: 700
}

.inner-three-service i {
    font-size: 60PX;
    COLOR: #106eea
}

.accordion-style3 .panel .panel-heading {
    background: #000;
    color: #fff;
    border-radius: 0;
    padding: 18px 28px 19px
}

.text-deep-pink {
    color: #ff214f;
    color: #ff6786;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    text-align: left;
    color: #fff !important
}

.big-section1 {
    background-color: #f9f9f9;
    padding: 50px 0
}

.bottom-content {
    padding: 50px 0
}

.accordion-style2 .panel .panel-heading {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 18px 28px 19px;
    box-shadow: 5px 5px 10px #d3d3d3
}

.text-extra-dark-gray {
    color: #232323
}

.bgf {
    background-size: cover;
    height: 627px;
    background-position: center
}

.padding-thirteen-all {
    padding: 13% 13% 5% 13%
}

.benifits {
    background-color: #f7f7f7
}

.border {
    border: 1px solid #dee2e6 !important
}

.tags li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline
}

.tag3 a {
    font-size: 120%;
    color: #004253
}

.tag2 a {
    font-weight: 700;
    color: #66aad7
}

.seo {
    padding: 40px 0
}

.navbar-nav>li:hover>.dropdown-menu {
    display: block;
    background: #333;
    left: 0;
    width: 270px
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5
}

.dropdown-menu>li>a {
    display: block;
    line-height: 26px;
    color: #fff
}

.fa-mobile:before {
    content: "\f10b"
}

.fa-envelope-o:before {
    content: "\f003"
}

.carousel-caption {
    right: 20%;
    left: 14%;
    top: 31%;
    padding-bottom: 30px
}

.banner-title {
    font-size: 40px;
    text-transform: uppercase
}

.carousel-caption p {
    font-size: 18px !important
}

.mytb {
    padding: 10px 30px 10px 29px;
    text-transform: uppercase;
    margin-top: 2%;
    background: #e20031;
    border: none
}

.btn-style-nine:hover .txt {
    color: #fff;
    text-decoration: none !important
}

a:hover {
    text-decoration: none
}

.btn-primary:hover {
    color: #106eea;
    background-color: #fff;
    border-color: #fff;
}

@media (max-width:768px) {
    .bottom-fixed-contact {
        display: block
    }

    .top-nav-sec {
        display: none
    }

    .contact-info a {
        font-size: 13px !important
    }

    .patern-layer {
        display: none
    }

    .patern-layer-two {
        display: none
    }

    .mt {
        height: auto
    }

    .ct-heading .item--title {
        font-size: 20px !important
    }

    .navbar-default .navbar-collapse {
        background: #333;
        transition: all 0.2s linear;
        overflow: hidden;
    }

    .banner-title {
        font-size: 18px
    }

    a.logo img.img-responsive {
        width: 76%;
        margin-top: 16px;
    }

    .navbar-default .navbar-nav>li>a {
        text-align: left;
    }

    .navbar-default .navbar-collapse {
        overflow: scroll;
    }

    section.tech .col-md-2 {
        width: 40%;
        float: left;
    }
}

.tech {   
    background-position: center center;
    background-size: cover;
    position: relative;
    padding: 60px 0 139px;
}
.background-overlay {
    background-color: #000;   
    background-position: center center;
    background-repeat: no-repeat;
    opacity: .75;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.sec-title .title {
    position: relative;
    color: #fe4c1c;
    font-size: 18px;
    font-weight: 600;
    padding-right: 60px;
    display: inline-block;
    font-family: Montserrat, sans-serif;
}
.sec-title .title:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 12px;
    height: 2px;
    width: 50px;
    background-color: #106eea;
}

.tech h3{
    margin-top: 10px;
}

/*** webdev05 ***/
.webdev05{
    position: relative;
    padding: 70px 0 60px;
    background: #eee;
}

.webdev05 ul{    
    list-style: none;
}

.webdev05 ul li{
    display: flex;
    border-bottom: 1px solid #ecc;
    margin-bottom: 10px;
    padding: 0px 0 7px 0;
    vertical-align: middle;
}
.webdev05 ul li .text{    
    font-size: 20px;
    padding-left: 15px;
    padding-top: 5px;
}
.webdev05 ul li i{
    font-size: 28px;
    color: #106eea;
}