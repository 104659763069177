.introsec{
    position: relative;
    padding: 40px 0 40px 0!important;
}
.introsec .pad-about {
    padding: 0 0 20px 0!important;
}
.introsec .no-padding {
    padding: 0;
    margin: 0;
}
.introsec .font-weight-600 {
    font-weight: 600;
}
.introsec .heading-main {
    color: #23262a;
    font-weight: 700;
    font-size: 33px;
}
.introsec .heading-main h2 {
    color: #fff;
    font-weight: bolder;    
    font-size: 33px;
}
.introsec .heading-main h1, 
.introsec .heading-main h2 {
    color: #23262a;
    font-weight: 700;
    font-size: 33px;
    font-weight: 600;
    margin: 8px 0 2px;
    text-align: center;
    line-height: 1.35;
}
.introsec .text-black {
    color: #000;
}
.introsec .heading-main h1 {
    color: #23262a;
    font-weight: 700;
    font-size: 37px;    
}
.introsec .about-text {
    line-height: 28px;
    font-size: 20px;
    padding-top: 20px;
    visibility: visible;
    animation-name: fadeInUp;
    letter-spacing: .2px;
}
.introsec .inner-counting {
    border-right: dashed 2px #ccc;
    text-align: center;
}
.introsec .numbers {
    font-size: 60px;
    font-weight: 700;
    color: #000;
}
.introsec .bottom-title {
    font-size: 25px;
    color: #afa7a7;
}
.introsec .more-link {
    margin-top: 12px;
}
.introsec .inner-counting a {
    color: #d8002d;
    font-size: 15px;
    transition: .3s;
    text-decoration: none;
}
.introsec .inner-counting:nth-child(3) {
    border-right: 0;
}