/** mob01 **/ 
.mob01 {   
    padding: 60px 0;
}
.mob01 .ct-heading {
    position: relative;
}
.mob01 .ct-heading .item--title {
    font-size: 35px;
    /* line-height: 44px; */
    font-weight: 700;
    margin-bottom: 31px;
}
.mob01 .ct-heading .item--title.st-line-left1 span {
    padding-left: 82px;
    position: relative;
    display: inline-block;
}
.mob01 .ct-heading .item--title.st-line-left1 span i {
    width: 54px;
    height: 3px;
    position: absolute;
    top: 21px;
    left: 0;
    background-color: #e20031;
    background-image: -webkit-gradient(linear, left top, right top, from(#e20031), to(#c1282a));
    background-image: -webkit-linear-gradient(left, #e20031, #c1282a);
    background-image: -moz-linear-gradient(left, #e20031, #c1282a);
    background-image: -ms-linear-gradient(left, #e20031, #c1282a);
    background-image: -o-linear-gradient(left, #e20031, #c1282a);
    background-image: linear-gradient(left, #e20031, #c1282a);
}
.mob01 .text {  
    line-height: 28px;
}
.mob01 .row.labl-serv {
    display: flex;
    justify-content: space-between;
}
.mob01 .labl-serv .card {
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    padding: 20px;
    border-radius: 10px;
    display:inline-block;
}
.mob01 .mobi1 {
    width: 90px;
    height: 90px;   
    background-size: 100%;
    display: inline-block;
    transition: .5s;
}
.mob01 .mobi2 {
    width: 90px;
    height: 90px;    
    background-size: 100%;
    display: inline-block;
    transition: .5s;
}
.mob01 .mobi3 {
    width: 90px;
    height: 90px;   
    background-size: 100%;
    display: inline-block;
    transition: .5s;
}
.mob01 h2.card-title {
    font-size: 20px;
}

.mob01 .card-text{
    /* min-height: 350px; */
}
.mob01 hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

/*** mob02 ***/
.mob02 {   
    padding: 50px 0px;    
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    /* z-index: 1; */
    color: #ffffff;    
}
.mob02:after {
    background: #000;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    z-index: -2;
}
.mob02 .card {
    cursor: pointer
}
.mob02 .hd {
    font-size: 25px;
    font-weight: 550
}
.mob02 .card.hover
{
    box-shadow: 0 20px 40px rgba(0, 0, 0, .2)
}
.mob02 .card-title {
    font-weight: 600;
    color: #106eea;
}
.mob02 .card-text {   
    color: #333;
    /* min-height: 25vh; */
}

.mob02 .ft {
    margin-top: 25px
}
.mob02 .chk {
    margin-bottom: 5px
}
.mob02 .rck {
    margin-top: 20px;
    padding-bottom: 15px
}
.mob02 .card-subtitle {    
    min-height: 150px;
}
.mob02 .imageCenterAlign{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;    
}
.mob02 .imageCenterAlign img{    
    width: 65px;    
}
.mob02 .card{  
    padding: 25px;      
    
}