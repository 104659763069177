.geobin-heading-title {
    overflow: hidden;
}
.geobin-heading-title h2 {
    color: #2f2c2c;
    font-size: 44px;
    /* margin-bottom: 25px; */
}
.special-header {
    /* font-size: 80px; */
    font-weight: 800;
    color: #fff;
    /* padding-top: 23px; */
    /* min-height: 75px; */
    -webkit-text-stroke: 2px #091ceb;
    font-size: 70px;
    white-space: nowrap;
    overflow: hidden;
    animation: typewriter 2s steps(5) infinite alternate, blink 400ms steps(5) infinite normal;
    /* border-right: 5px solid black; */
}
.animate-border {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: #2882c3;
    overflow: hidden;
}
.pull-left {
    float: left!important;
}
.animate-border:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 15px;
    bottom: 0;
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    -webkit-animation: animborder 2s linear infinite;
    animation: animborder 2s linear infinite;
}

.item-style-custom-1 {
    margin: auto !important;
    /* width: 20% !important; */
    min-height: 210px !important;
    position: relative;
}
.main-tabs {
    margin-bottom: 25px;
}
.colorful-tab .nav-tabs .nav-item .nav-link.active {
    box-shadow: none;
    color: #FFFFFF;
    background: rgb(255, 155, 143);
    background: linear-gradient(-35deg, rgb(68 150 209) 0%, rgb(77 199 230) 100%);
}
.colorful-tab .nav-tabs .nav-item .nav-link {
    padding: 30px 15px;
}
.colorful-tab .nav-tabs .nav-item .nav-link {
    background-color: rgb(165 165 165 / 15%);
    font-size: 15px;
    border: 0px;
    border-radius: 5px;
    line-height: normal;
    color: #3d59a8;
    font-weight: bolder;
}

.colorful-tab .nav-tabs {
    border-bottom:none;
} 


.colorful-tab .main-tabs .nav-item .nav-link.active::before {
    border-top-color: rgb(68 150 209);
    filter: none;
}
.main-tabs .nav-item .nav-link.active::before {
    opacity: 1;
    visibility: visible;
}
.main-tabs .nav-item .nav-link::before {
    position: absolute;
    content: "";
    bottom: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 40px;
    width: 40px;
    border-top: solid #FFFFFF;
    border-left: solid transparent;
    border-right: solid transparent;
    border-width: 20px;
    -webkit-filter: drop-shadow(0 5px 3px rgba(3, 27, 78, 0.08));
    filter: drop-shadow(0 5px 3px rgba(3, 27, 78, 0.08));
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
}

.colorful-tab .tab-content .tab-pane .single-service-preview {
    box-shadow: 0px 10px 30px 0px rgba(248, 72, 139, 0.2);
}
.single-service-preview {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    background-color: white;
    -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.06);
    padding:25px;
}
.service-preview-img img{
    width:100%;
}

.transition01 {
    --g: 4px;     /* the gap */
    --b: 2px;    /* border thickness*/
    --c: #669706; /* the color */    
    
    padding: calc(var(--g) + var(--b));
    --_c: #0000 0 25%, var(--c) 0 50%;
    --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
    --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
    background:
      var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
      var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
    background-size: 200% var(--b),var(--b) 200%;
    cursor: pointer;
    filter: grayscale(50%);
    transition: .3s;
  }
.transition01:hover {
    --_p: 75%;
    filter: grayscale(0%);
  }
  