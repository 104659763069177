  .process {
    padding: 70px 0 60px;
  }
  
  .process .process-box {
    padding: 25px;
    width: 95%;
    position: relative;
    text-align: center;       
    background-color: #DDE3F5;
    border-radius: 23px 23px 23px 23px;
    box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.5);
  }  

  .process .process-box i:nth-child(odd) {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 50px;
    background: #DDE3F5;
    color: #126fea;
    width: 60px;
    height: 60px;
    line-height: 0;
    border-radius: 50px;    
    display: inline-flex;
    align-items: center;
    justify-content: center;    
  }
  .process .process-box i:nth-child(even) {
    position: absolute;
    top: 35%;
    right: -78px;
    transform: translateX(-50%);
    font-size: 30px;
    color: #ddd;
    width: 60px;
    height: 60px;
    line-height: 0;   
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
  }
  
  .process .process-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #062b5b;
  }
  
  .process .process-box p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
  }
  .process .process-box h3 {
    padding-top: 10px;
  }
 

  .process .overlay {
    position: absolute;
    bottom: 5%;
    right: 5%;
    opacity: .09;
  }
  .process .process-box .overlay span {    
    font-size: 60px;
    vertical-align: bottom;
    line-height: 0;
    color: #656565;
  }