/** digm01 **/ 
.digm01 {  
    background-position: 0 0;
    background-repeat: no-repeat; 
}
.digm01 .sec-title .title {
    position: relative;
    color: #106eea;
    font-size: 18px;
    font-weight: 600;
    padding-right: 60px;
    display: inline-block;
    font-family: Montserrat, sans-serif;
}
.digm01 .sec-title .title:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 12px;
    height: 2px;
    width: 50px;
    background-color: #106eea;
}
/** digm02 **/ 
.digm02 {   
    background: #f7f7f7;
    background-size: cover;
    position: relative;   
}
.digm02 .grid-item-holder {
    padding: 20px 28px 30px;
    position: relative;
    height: 100%;    
}
.grid-item-holder .item--content{
    font-size: 18px;
}

